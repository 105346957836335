import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Common/Subaccount/OrderHistory/Mobile.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/Table/Body.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Common/Subaccount/OrderHistory/Header.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Common/Subaccount/OrderHistory/Row.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Common/Table/Wrapper.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "w-full h-full" };
const _hoisted_2 = {
  key: 0,
  class: "table"
};
import { Status } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "OrderHistory",
  props: {
    status: {
      type: Object,
      default: () => new Status()
    }
  },
  setup(__props) {
    const derivativeStore = useDerivativeStore();
    const orders = computed(() => derivativeStore.subaccountOrderHistory);
    return (_ctx, _cache) => {
      const _component_PartialsCommonSubaccountOrderHistoryMobile = __nuxt_component_0;
      const _component_CommonEmptyList = __nuxt_component_1;
      const _component_CommonTableBody = __nuxt_component_2;
      const _component_PartialsCommonSubaccountOrderHistoryHeader = __nuxt_component_3;
      const _component_PartialsCommonSubaccountOrderHistoryRow = __nuxt_component_4;
      const _component_CommonTableWrapper = __nuxt_component_5;
      const _component_AppHocLoading = __nuxt_component_6;
      return _openBlock(), _createBlock(_component_AppHocLoading, {
        status: __props.status,
        "loader-class": __props.status.isLoading() ? "relative" : ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_CommonTableBody, {
              "show-empty": _unref(orders).length === 0,
              class: "sm:hidden mt-3 max-h-lg overflow-y-auto"
            }, {
              empty: _withCtx(() => [
                _createVNode(_component_CommonEmptyList, {
                  message: _ctx.$t("trade.emptyOrders"),
                  class: "grow pb-4 bg-gray-900"
                }, null, 8, ["message"])
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(orders), (order, index) => {
                  return _openBlock(), _createBlock(_component_PartialsCommonSubaccountOrderHistoryMobile, {
                    key: `mobile-derivative-orders-${index}-${order.orderHash}`,
                    class: "col-span-1",
                    "is-spot": false,
                    order
                  }, null, 8, ["order"]);
                }), 128))
              ]),
              _: 1
            }, 8, ["show-empty"]),
            _createVNode(_component_CommonTableWrapper, {
              "break-md": "",
              class: "hidden sm:block"
            }, {
              default: _withCtx(() => [
                _unref(orders).length > 0 ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
                  _createVNode(_component_PartialsCommonSubaccountOrderHistoryHeader),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(orders), (order, index) => {
                      return _openBlock(), _createBlock(_component_PartialsCommonSubaccountOrderHistoryRow, {
                        key: `order-${index}`,
                        order
                      }, null, 8, ["order"]);
                    }), 128))
                  ])
                ])) : (_openBlock(), _createBlock(_component_CommonEmptyList, {
                  key: 1,
                  message: _ctx.$t("trade.emptyOrders"),
                  "data-cy": "universal-table-nothing-found",
                  class: "grow pb-4"
                }, null, 8, ["message"]))
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["status", "loader-class"]);
    };
  }
});
